import React from 'react';
import {
  Chair,
  LocalParking,
  EmojiPeople,
  Map,
  AccessTime,
  Schedule,
  CalendarToday,
  Group,
  Devices,
  Save,
  Person,
} from '@mui/icons-material';

export const scheduleSidebarItems = [
  { text: 'People', icon: <EmojiPeople />, path: '/schedule/people' },
  { text: 'Desks', icon: <Chair />, path: '/schedule/desks' },
  { text: 'Parking', icon: <LocalParking />, path: '/schedule/parking' },
  { text: 'Timetable View', icon: <Schedule />, path: '/schedule/timeview' },
];

export const settingsSidebarItems = [
  { text: 'Floor plan', icon: <Map />, path: '/settings/floor-plan' },
  { text: 'Desks', icon: <Chair />, path: '/settings/desks' },
  { text: 'Parking', icon: <LocalParking />, path: '/settings/parking' },
  { text: 'Timeslots', icon: <AccessTime />, path: '/settings/timeslots' },
  {
    text: 'Recurring schedule',
    icon: <CalendarToday />,
    path: '/settings/recurring-schedule',
  },
  { text: 'User Directory', icon: <Group />, path: '/settings/users' },
  { text: 'Amenities', icon: <Devices />, path: '/settings/amenities' },
  { text: 'SpaceSaver', icon: <Save />, path: '/settings/spacesaver' },
  { text: 'Account', icon: <Person />, path: '/settings/account' },
];
