import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Grow,
} from '@mui/material';
import UserForm from './userForm';
import theme from 'theme';
import GenericTable from 'components/common/table';
import { usePagination } from 'helpers/hooks/usePagination';
import { rowConfigForUsers } from './rowConfigForUsers';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const mockUsers = {
  message: 'Success',
  total: 4,
  pageSize: 10,
  page: 1,
  totalPage: 1,
  previousPage: 0,
  requireUseOPredefinedTimeslots: false,
  nextPage: 0,
  hasNext: false,
  hasPrevious: false,
  data: [
    {
      id: 1,
      firstName: 'John',
      lastName: 'Doe',
      email: 'john.doe@example.com',
      phone: '1234567890',
    },
    {
      id: 2,
      firstName: 'Jane',
      lastName: 'Smith',
      email: 'jane.smith@example.com',
      phone: '9876543210',
    },
    {
      id: 3,
      firstName: 'Alice',
      lastName: 'Johnson',
      email: 'alice.johnson@example.com',
      phone: '1231231234',
    },
    {
      id: 4,
      firstName: 'Jordance',
      lastName: 'Arsov',
      email: 'joce.arsov@etarative.com',
      phone: '38970201187',
    },
  ],
};

const UserDirectory: React.FC = () => {
  const [users, setUsers] = useState<User[]>(mockUsers.data);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Partial<User> | undefined>();

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination({
      initialPage: 1,
      initialRowsPerPage: 10,
    });

  const handleAddUser = () => {
    setSelectedUser(undefined);
    setOpen(true);
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleSaveUser = (user: Partial<User>) => {
    if (user.id) {
      setUsers((prev) =>
        prev.map((u) => (u.id === user.id ? { ...u, ...user } : u))
      );
    } else {
      setUsers((prev) => [...prev, { id: Date.now(), ...user } as User]);
    }
    setOpen(false);
  };

  return (
    <Box p={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5" gutterBottom>
          User Directory
        </Typography>
        <Button
          variant="contained"
          onClick={handleAddUser}
          sx={{
            color: theme.palette.neutral.contrastText,
            fontWeight: 'bold',
            minWidth: '120px',
          }}
        >
          Add User
        </Button>
      </Box>
      <Box>
        <Typography variant="body1" gutterBottom>
          Invite other employees, such as an office manager, or remove inactive
          accounts.
        </Typography>
      </Box>
      <GenericTable
        headers={['Name', 'Last Name', 'Email', 'Phone']}
        data={users}
        rowConfig={rowConfigForUsers(handleEditUser)}
        totalCount={mockUsers.total}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Grow}
        TransitionProps={{ timeout: 300 }}
      >
        <DialogTitle textAlign={'center'}>
          {selectedUser
            ? 'Adjust user information'
            : 'Create a new user for your organization'}
        </DialogTitle>
        <UserForm
          user={selectedUser}
          onSave={handleSaveUser}
          onCancel={() => setOpen(false)}
        />
      </Dialog>
    </Box>
  );
};

export default UserDirectory;
