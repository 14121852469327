import React from 'react';
import {
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define the Schedule interface
export interface Schedule {
  id: string;
  repeat: string;
  days: string;
  ends: string;
  isDefault: boolean;
}

export const rowConfigForSchedule = (
  onRowClick: (schedule: Schedule) => void
) => ({
  key: (item: Schedule) => item.id,
  renderHeader: (isMobile: boolean) => {
    if (isMobile) return null;
    return (
      <>
        <TableCell>Repeat</TableCell>
        <TableCell>Days</TableCell>
        <TableCell>Ends</TableCell>
        <TableCell>Default</TableCell>
      </>
    );
  },
  renderRow: (item: Schedule, isMobile: boolean) => {
    if (isMobile) {
      return (
        <TableCell colSpan={4}>
          <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>{item.repeat}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Days: {item.days}</Typography>
              <Typography>Ends: {item.ends}</Typography>
              <Typography>Default: {item.isDefault ? 'Yes' : 'No'}</Typography>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      );
    }

    return (
      <>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.repeat}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.days}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.ends}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {' '}
          {item.isDefault ? 'Yes' : 'No'}
        </TableCell>
      </>
    );
  },
  renderCells: (item: Schedule, isMobile: boolean) => {
    if (isMobile) {
      return (
        <TableCell colSpan={4}>
          <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>{item.repeat}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Days: {item.days}</Typography>
              <Typography>Ends: {item.ends}</Typography>
              <Typography>Default: {item.isDefault ? 'Yes' : 'No'}</Typography>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      );
    }

    return (
      <>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.repeat}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.days}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.ends}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.isDefault ? 'Yes' : 'No'}
        </TableCell>
      </>
    );
  },
});
