import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Grow,
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import theme from 'theme';

const RecurrenceDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [frequency, setFrequency] = useState(1);
  const [unit, setUnit] = useState('week');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [endOption, setEndOption] = useState('never');
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [occurrences, setOccurrences] = useState<number>(1);

  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  const toggleDay = (day: string) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleSave = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const recurrenceData = {
      frequency,
      unit,
      selectedDays,
      endOption,
      endDate: endOption === 'on' ? endDate : null,
      occurrences: endOption === 'after' ? occurrences : null,
    };
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
    >
      <DialogTitle>Custom Recurrence</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <Typography>Repeat every</Typography>
          <TextField
            type="number"
            value={frequency}
            onChange={(e) => setFrequency(Number(e.target.value))}
            inputProps={{ min: 1 }}
            size="small"
            sx={{ width: 80 }}
          />
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <Select
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              displayEmpty
            >
              <MenuItem value="day">day</MenuItem>
              <MenuItem value="week">week</MenuItem>
              <MenuItem value="month">month</MenuItem>
              <MenuItem value="year">year</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {unit === 'week' && (
          <Box display="flex" gap={1} mb={2}>
            {daysOfWeek.map((day, index) => (
              <Button
                key={index}
                variant={selectedDays.includes(day) ? 'contained' : 'outlined'}
                onClick={() => toggleDay(day)}
                sx={{
                  width: 40,
                  height: 40,
                  minWidth: 0,
                  borderRadius: '50%',
                }}
              >
                {day}
              </Button>
            ))}
          </Box>
        )}
        <Typography mb={1}>Ends</Typography>
        <RadioGroup
          value={endOption}
          onChange={(e) => setEndOption(e.target.value)}
        >
          <FormControlLabel value="never" control={<Radio />} label="Never" />
          <FormControlLabel
            value="on"
            control={<Radio />}
            label={
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={endOption !== 'on'}
                  slots={{
                    textField: TextField,
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { width: 160, ml: 2 },
                    },
                  }}
                />
              </LocalizationProvider>
            }
          />
          <FormControlLabel
            value="after"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>After</Typography>
                <TextField
                  type="number"
                  value={occurrences}
                  onChange={(e) => setOccurrences(Number(e.target.value))}
                  size="small"
                  disabled={endOption !== 'after'}
                  sx={{ width: 80 }}
                />
                <Typography>occurrences</Typography>
              </Box>
            }
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{ color: theme.palette.neutral.contrastText }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurrenceDialog;
