/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import RecurrenceDialog from './scheduleDialog';
import theme from 'theme';
import GenericTable from 'components/common/table';
import { useStaticTimeslotsOverview } from 'hooks/recurringSchedule/mockData';
import { rowConfigForSchedule } from './scheduleRowConfig';

const RecurringSchedule: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data } = useStaticTimeslotsOverview();

  const handleRowClick = (schedule: any) => {
    setDialogOpen(true);
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <Box>
      <Box sx={{ paddingLeft: 0, marginTop: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Recurring Schedule
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          Configure the most commonly used schedules for your company.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          sx={{
            color: theme.palette.neutral.contrastText,
            fontWeight: 'bold',
            minWidth: { xs: '100%', md: '120px' },
          }}
        >
          New Schedule
        </Button>
      </Box>

      <GenericTable
        headers={['Repeat', 'Days', 'Ends', 'Default']}
        data={data.timeslots}
        rowConfig={rowConfigForSchedule(handleRowClick)}
        totalCount={data.total}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <RecurrenceDialog open={isDialogOpen} onClose={handleCloseDialog} />
    </Box>
  );
};

export default RecurringSchedule;
