import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  SelectChangeEvent,
  Grow,
} from '@mui/material';
import useCheckInOut from 'hooks/check-in-out/useCheckInOut';
import { getDefaultDeskId } from 'services/resources/defaultMap';
import { checkInTypeOptions } from 'helpers';
import { useStatusCheckInOut } from 'hooks/check-in-out/useStatusCheckInOut';

interface PopupProps {
  onClose: () => void;
}

const CheckInOutDialog: React.FC<PopupProps> = ({ onClose }) => {
  const [selectedType, setSelectedType] = useState<string | null>('');
  const [error, setError] = useState<Record<string, string>>({});
  const { mutateAsync, isPending } = useCheckInOut();
  const { refetch } = useStatusCheckInOut();

  const handleCheckIn = async () => {
    setError({});

    const mapId = getDefaultDeskId();

    await mutateAsync(
      { type: selectedType, mapId, fromDesk: false },
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (err: any) => {
          const errors = err.errors || [];
          const mappedErrors: Record<string, string> = {};
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errors.forEach((apiError: any) => {
            mappedErrors[apiError.field] = apiError.message;
          });
          setError(mappedErrors);
        },
        onSuccess: () => {
          onClose();
        },
      }
    );
    refetch();
  };

  const handleSelectChange = (event: SelectChangeEvent<string | null>) => {
    setSelectedType(event.target.value as string | null);
    setError((prevErrors) => ({ ...prevErrors, type: '' }));
  };

  return (
    <Dialog
      open
      onClose={onClose}
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
    >
      <DialogTitle>Select Check-in Type</DialogTitle>
      <DialogContent>
        <FormControl fullWidth error={Boolean(error.type)} margin="normal">
          <InputLabel>Type</InputLabel>
          <Select
            value={selectedType}
            onChange={handleSelectChange}
            label="Type"
          >
            <MenuItem value="">
              <em>Select Type</em>
            </MenuItem>
            {checkInTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {error.type && <FormHelperText>{error.type}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleCheckIn}
          variant="contained"
          color="secondary"
          disabled={isPending || !selectedType}
        >
          {isPending ? <CircularProgress size={24} /> : 'Check in'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckInOutDialog;
