// Static mock data
const staticTimeslots = {
  message: 'Success',
  total: 3,
  pageSize: 10,
  page: 1,
  totalPage: 1,
  previousPage: 0,
  requireUseOPredefinedTimeslots: false,
  nextPage: 0,
  hasNext: false,
  hasPrevious: false,
  timeslots: [
    {
      id: '1',
      from: '09:00 AM',
      to: '05:00 PM',
      repeat: 'Daily',
      days: 'Mon - Fri',
      ends: 'Never',
      isDefault: true,
    },
    {
      id: '2',
      from: '10:00 AM',
      to: '06:00 PM',
      repeat: 'Weekly',
      days: 'Sat - Sun',
      ends: '12/31/2024',
      isDefault: false,
    },
    {
      id: '3',
      from: '08:00 AM',
      to: '04:00 PM',
      repeat: 'Monthly',
      days: '1st of the Month',
      ends: '06/30/2025',
      isDefault: false,
    },
  ],
};

// Hook to return static data
export const useStaticTimeslotsOverview = () => {
  return {
    data: staticTimeslots,
    isLoading: false,
    isError: false,
    refetch: () => {},
  };
};
