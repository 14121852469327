import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../../../theme';

const FooterBar = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.grey[200],
        zIndex: 1200,
        position: 'relative',
        color: theme.palette.neutral.main,
      }}
    >
      <Typography variant="body1" align="center">
        © {new Date().getFullYear()} My Desk App. All rights reserved.
      </Typography>
    </Box>
  );
};

export default FooterBar;
