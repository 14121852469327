import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  AuthenticatedAdminRoute,
  AuthenticatedRoute,
} from './components/common/authorization';
import Login from 'components/features/login';
import {
  scheduleSidebarItems,
  settingsSidebarItems,
} from 'components/common/sideBar/sideBarItems';
import '@fontsource/noto-sans';
import { Box } from '@mui/material';
import Layout from 'components/common/layout';
import { LoadingSpinner } from 'components/common/loadingSpinner';
import { ResourceType } from 'components/common/enums/resourceType';
import { DefaultTab } from 'components/common/enums/defaultTab';
import React from 'react';
import RecurringSchedule from 'components/features/recurringSchedule';
import UserDirectory from 'components/features/userDirectory';

const People = lazy(() => import('./components/features/people'));
const ResourcePage = lazy(() => import('./components/features/resourcePage'));
const QRCodeList = lazy(() => import('./components/features/qr-code-list'));
const TimeView = lazy(() => import('./components/features/timeview'));
const CheckInOut = lazy(() => import('./components/features/check-in-out'));
const Timeslots = lazy(() => import('./components/features/timeslots'));
const FloorPlan = lazy(() => import('./components/features/floorPlan'));
const EditResourcePage = lazy(
  () => import('./components/features/editResourcePage')
);
const Amenity = lazy(() => import('./components/features/amenity'));
const SpaceSaver = lazy(() => import('./components/features/spaceSaver'));
const Account = lazy(() => import('./components/features/account'));

type AppProps = {
  pca: IPublicClientApplication;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    handle: { crumb: () => 'Home' },
  },
  {
    path: 'check-in-out/map/:mapId',
    element: (
      <AuthenticatedRoute
        element={<Layout sidebarItems={scheduleSidebarItems} />}
      />
    ),
    handle: { crumb: () => 'Check-In/Out' },

    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <CheckInOut />
          </Suspense>
        ),
        handle: { crumb: () => 'Map' },
      },
    ],
  },
  {
    path: 'schedule',
    element: (
      <AuthenticatedRoute
        element={<Layout sidebarItems={scheduleSidebarItems} />}
      />
    ),
    handle: { crumb: () => 'Schedule' },

    children: [
      {
        path: 'people/map?/:map?/resource?/:resourceId?',
        element: (
          <Suspense fallback={null}>
            <People />
          </Suspense>
        ),
        handle: { crumb: () => 'People' },
      },
      {
        path: 'desks',
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <ResourcePage
              type={ResourceType.FLOOR}
              defaultTab={DefaultTab.MAP}
            />
          </Suspense>
        ),
        handle: { crumb: () => 'Desks' },
      },
      {
        path: 'parking',
        element: (
          <Suspense fallback={null}>
            <ResourcePage
              type={ResourceType.PARKING}
              defaultTab={DefaultTab.TABLE}
            />
          </Suspense>
        ),
        handle: { crumb: () => 'Parking' },
      },
      {
        path: 'timeview',
        element: (
          <Suspense fallback={null}>
            <TimeView />
          </Suspense>
        ),
        handle: { crumb: () => 'TimeView' },
      },
    ],
  },
  {
    path: 'settings',
    element: (
      <AuthenticatedAdminRoute
        element={<Layout sidebarItems={settingsSidebarItems} />}
      />
    ),
    handle: { crumb: () => 'Settings' },
    children: [
      {
        path: 'floor-plan',
        element: (
          <Suspense fallback={null}>
            <FloorPlan />
          </Suspense>
        ),
        handle: { crumb: () => 'Floor Plan' },
      },
      {
        path: 'desks',
        element: (
          <Suspense fallback={null}>
            <EditResourcePage type={ResourceType.FLOOR} />
          </Suspense>
        ),
        handle: { crumb: () => 'Desks' },
      },
      {
        path: 'parking',
        element: (
          <Suspense fallback={null}>
            <EditResourcePage type={ResourceType.PARKING} />
          </Suspense>
        ),
        handle: { crumb: () => 'Parking' },
      },
      {
        path: 'timeslots',
        element: (
          <Suspense fallback={null}>
            <Timeslots />
          </Suspense>
        ),
        handle: { crumb: () => 'Timeslots' },
      },
      {
        path: 'recurring-schedule',
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <RecurringSchedule />
          </Suspense>
        ),
        handle: { crumb: () => 'Recurring Schedule' },
      },
      {
        path: 'qr-codes/map/:mapId',
        element: (
          <Suspense fallback={null}>
            <QRCodeList />
          </Suspense>
        ),
        handle: { crumb: () => 'QR Codes' },
      },
      {
        path: 'users',
        element: (
          <Suspense fallback={null}>
            <UserDirectory />
          </Suspense>
        ),
        handle: { crumb: () => 'User Directory' },
      },
      {
        path: 'amenities',
        element: (
          <Suspense fallback={null}>
            <Amenity />
          </Suspense>
        ),
        handle: { crumb: () => 'Amenities' },
      },
      {
        path: 'spacesaver',
        element: (
          <Suspense fallback={null}>
            <SpaceSaver />
          </Suspense>
        ),
        handle: { crumb: () => 'Space Saver' },
      },
      {
        path: 'account',
        element: (
          <Suspense fallback={null}>
            <Account />
          </Suspense>
        ),
        handle: { crumb: () => 'Account' },
      },
    ],
  },
  {
    path: '*',
    element: <Box>404 - Page Not Found</Box>,
    handle: { crumb: () => '404' },
  },
]);

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <RouterProvider router={router} />
    </MsalProvider>
  );
}

export default App;
