import React from 'react';
import {
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User } from '.';

export const rowConfigForUsers = (onRowClick: (user: User) => void) => ({
  key: (item: User) => item.id.toString(),
  renderHeader: (isMobile: boolean) => {
    if (isMobile) return null;
    return (
      <>
        <TableCell>First Name</TableCell>
        <TableCell>Last Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Phone</TableCell>
      </>
    );
  },
  renderRow: (item: User, isMobile: boolean) => {
    if (isMobile) {
      return (
        <TableCell colSpan={4}>
          <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {item.firstName} {item.lastName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Email: {item.email}</Typography>
              <Typography>Phone: {item.phone}</Typography>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      );
    }

    return (
      <>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.firstName}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.lastName}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.email}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.phone}
        </TableCell>
      </>
    );
  },
  renderCells: (item: User, isMobile: boolean) => {
    if (isMobile) {
      return (
        <TableCell colSpan={4}>
          <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {item.firstName} {item.lastName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Email: {item.email}</Typography>
              <Typography>Phone: {item.phone}</Typography>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      );
    }

    return (
      <>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.firstName} {item.lastName}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.lastName}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.email}
        </TableCell>
        <TableCell onClick={() => onRowClick(item)} sx={{ cursor: 'pointer' }}>
          {item.phone}
        </TableCell>
      </>
    );
  },
});
