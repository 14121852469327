import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Grid2 } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface UserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface UserFormProps {
  user?: Partial<UserFormValues>;
  onSave: (user: UserFormValues) => void;
  onCancel: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ user, onSave, onCancel }) => {
  const initialValues: UserFormValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
  });

  const handleSubmit = (values: UserFormValues) => {
    onSave(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <Box p={3} display="flex" flexDirection="column" gap={3}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="First Name"
                  name="firstName"
                  fullWidth
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  fullWidth
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <PhoneInput
                  country="mk"
                  value={values.phone}
                  onChange={(phone) => setFieldValue('phone', phone)}
                  inputStyle={{ width: '100%' }}
                />
                {touched.phone && errors.phone && (
                  <Box mt={1} color="red" fontSize="0.875rem">
                    {errors.phone}
                  </Box>
                )}
              </Grid2>
            </Grid2>
            <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
              <Button
                color="primary"
                onClick={onCancel}
                sx={{ minWidth: '120px' }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                {user ? 'Edit' : 'Save'}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
