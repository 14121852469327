import { useCallback, useState } from 'react';

interface UsePaginationResult {
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetPage: () => void;
}

interface UsePaginationProps {
  initialPage?: number;
  initialRowsPerPage?: number;
}

export const usePagination = ({
  initialPage = 1,
  initialRowsPerPage = 10,
}: UsePaginationProps): UsePaginationResult => {
  const [page, setPage] = useState(initialPage - 1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = useCallback((_event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    },
    []
  );

  const resetPage = useCallback(() => {
    setPage(initialPage - 1);
    setRowsPerPage(initialRowsPerPage);
  }, [initialPage, initialRowsPerPage]);

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPage,
  };
};
