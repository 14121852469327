import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomPagination from 'components/common/tablePagination';

interface RowConfig<T> {
  key: (item: T) => string;
  renderHeader?: (isMobile: boolean) => React.ReactNode;
  renderRow?: (item: T, isMobile: boolean) => React.ReactNode;
  renderCells?: (item: T, isMobile: boolean) => React.ReactNode;
}

interface GenericTableProps<T> {
  headers?: string[];
  data: T[];
  rowConfig: RowConfig<T>;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GenericTable = <T,>({
  headers,
  data,
  rowConfig,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: GenericTableProps<T>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const hasCustomHeader = Boolean(rowConfig.renderHeader);
  const hasCustomRow = Boolean(rowConfig.renderRow);

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table>
        {!isMobile && (
          <TableHead>
            <TableRow>
              {hasCustomHeader
                ? rowConfig.renderHeader!(isMobile)
                : headers?.map((header, index) => (
                    <TableCell key={index} align="center">
                      {header}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map((item) => (
            <TableRow key={rowConfig.key(item)}>
              {hasCustomRow
                ? rowConfig.renderRow!(item, isMobile)
                : rowConfig.renderCells?.(item, isMobile)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {totalCount > 0 && (
        <CustomPagination
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </TableContainer>
  );
};

export default GenericTable;
